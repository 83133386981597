<template>
  <Modal
      class="dashboard-modal"
      size="lg"
      :value="isCompanyAboutModalOpen"
      @input="updateModal"
      :header="false"
      :footer="false"
  >
    <div class="col-md-12 pl-0 pb-2">
      <div slot="header">
        <button
            type="button"
            aria-label="Close"
            class="close modal__close"
            @click="close"
        >
          <i class="material-icons" aria-hidden="true">close</i>
        </button>
        <h3 class="modal-title pl-0 text-primary-light">{{ $t('title') }}</h3>
      </div>
    </div>
    <div class="dashboard-modal__content">

      <tinymce-editor
          id="myEditor"
          v-model="editorContent"
          api-key="ffceq63r4f8ux7o18z1uh2hab5h9o2nney70hmaq18h0bmuc"
          model-events="change keydown blur focus paste"
          @selectionChange="handlerFunction"
          :init="{
         height: 400,
         menubar: false,
         inline: false,
         block_formats: 'Heading=h4;Paragraph=p',
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount emoticons'
         ],
         paste_as_text: true,
         setup:handlerFunction,
         toolbar:
           'undo redo | formatselect | bold italic | \
           bullist numlist | removeformat'
       }"></tinymce-editor>

      <AppError :errors="errors" v-if="errors"/>

      <div class="row mt-3 pb-1">
        <div class="col-xs-6">
          <button  :disabled="!editorContent || editorContent === content.about " @click="updateModal" class="btn btn-block btn-primary-light">{{ $t('change') }}</button>
        </div>
        <div class="col-xs-6">
          <button @click="close" class="btn btn-block btn-gray">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import Modal from 'uiv/src/components/modal/Modal';
import Errors from '../../utils/errors';
import AppError from '../AppError';
import Notifications from '../../services/notifications.service';

export default {
  i18nOptions: { namespaces: 'company-about-modal' },
  components: {
    Modal,
    AppError,
    'tinymce-editor': Editor,
  },
  data() {
    return {
      editorContent: null,
      loading: false,
      errors: new Errors(),
    };
  },
  watch: {
    content(newVal) {
      this.editorContent = newVal.about;
    },
  },
  computed: {
    ...mapState({
      isCompanyAboutModalOpen: state => state.companyProfile.isCompanyAboutModalOpen,
      content: state => state.companyProfile.company,
    }),
  },
  methods: {
    close() {
      this.$store.commit('companyProfile/isCompanyAboutModalOpen', false);
    },
    handlerFunction(ed) {
      ed.on('keyup', () => {
        this.editorContent = ed.getContent();
      });
    },
    updateModal() {
      this.errors.clear();

      this.$store.commit('companyProfile/updateAboutProp', this.editorContent);

      this.$store
        .dispatch('companyProfile/updateCompanyAbout')
        .then((res) => {
          this.errors.clear();
          if (res) {
            this.$store.dispatch('companyProfile/getCompanyProfile');
            Notifications.notify('success', res.message);
            this.close();
          }
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            this.errors.set(err.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

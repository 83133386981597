<template>
  <Modal
    class="dashboard-modal"
    :value="isCompanyProfileModalOpen"
    size="md"
    :header="false"
    :footer="false"
  >
    <div v-if="!newProfile" class="page__loading" key="loading">
      <AppLoading/>
    </div>
    <div class="col-md-12 pb-2">
      <div slot="header">
        <button
          type="button"
          aria-label="Close"
          class="close modal__close"
          @click="close"
        >
          <i class="material-icons" aria-hidden="true">close</i>
        </button>
        <h3 class="modal-title pl-0 text-primary-light">{{ $t('edit-profile') }}</h3>
      </div>
      <div class="dashboard-modal__content">
        <div class="panel--profile pt-4">
          <div class="profile__top">
            <div class="profile__top__avatar" v-bind:style="{ 'background-color': profile.anonymous_color }">
              <img v-if="newProfile && newProfile.company_logo" :src="newProfile.company_logo" alt="" />
              <span v-if="newProfile && !newProfile.company_logo">{{ userInitials(this.newProfile) }}</span>
            </div>
            <div class="profile__top__info">
              <h4 class="text-primary">{{ newProfile.name }}</h4>
            </div>
          </div>
        </div>
        <form
          class="company-profile-form"
          v-if="!successMessage && newProfile"
          enctype="multipart/form-data"
          @submit.prevent="updateAccount"
          novalidate>
          <AppInput
            class="mb-2"
            v-model="newProfile.name"
            type="text"
            :label="$t('name')"
            field="name"
            id="name"
            :errors="errors"
          />
          <AppInput
            class="mb-2"
            v-model="newProfile.address"
            type="text"
            :label="$t('address')"
            field="address"
            id="address"
            :errors="errors"
          />
          <AppInput
            class="mb-2"
            v-model="newProfile.website"
            type="text"
            :label="$t('website')"
            field="website"
            id="website"
            :errors="errors"
          />
          <div class="row">
            <div class="col-xs-6 w-100">
              <AppSelect
                class="mb-2"
                id="size"
                :value="{ value: newProfile.size }"
                :options='companySizes'
                :placeholder="$t('select-size')"
                :label="$t('size')"
                labelField="value"
                trackBy="value"
                field="size"
                :multiple="false"
                :errors="errors"
                @input="updateSize({size: $event })"
              />
            </div>
            <div class="col-xs-6 w-100">
              <AppSelect
                class="mb-2"
                :value="{ name: newProfile.timezone }"
                :options='timezones'
                :placeholder="$t('select-timezone')"
                :label="$t('timezone')"
                field="timezone"
                track-by="name"
                :multiple="false"
                :errors="errors"
                @input="updateTimezone({timezone: $event })"
              />
            </div>
          </div>
          <AppInput
            class="mb-2"
            v-model="newProfile.industry"
            type="text"
            :label="$t('industry')"
            field="industry"
            id="industry"
            :errors="errors"
          />
          <div class="row">
            <div class="col-md-7">
              <ProfileFormFileUpload
                class="sidebar__file-upload"
                :text="$t('upload-photo')"
                :textFileLimit="$t('image-size-limit')"
                :fileName="!newProfile.remove_media ? fileName : ''"
                :entry="entry"
                :errors="errors"
                :disabled="newProfile.remove_media"
                :placeholder="$t('upload')"
                field="main_image"
                @changed="uploadFile"
              />
            </div>
            <!-- <div class="col-md-5 form-group">
              <label>{{ $t('remove-logo') }}</label>
              <div class="row">
                <div class="col-md-12 relative">
                  <div class="container pt-4">
                    <label class="switch" for="checkbox5">
                      <input type="checkbox" id="checkbox5" v-model="newProfile.remove_media"/>
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="mt-2">
            <button class="btn btn-primary-light" type="submit">
              {{ $t("apply-changes") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';
import AppInput from '../forms/AppInput';
import AppLoading from '../AppLoading';
import ProfileFormFileUpload from '../profile/ProfileFormFileUpload';
import Errors from '../../utils/errors';
import AppSelect from '@/components/forms/AppSelect';

export default {
  i18nOptions: { namespaces: 'company-profile-modal' },
  components: {
    Modal,
    AppInput,
    AppLoading,
    ProfileFormFileUpload,
    AppSelect,
  },
  data() {
    return {
      password: null,
      password_confirmation: null,
      file: null,
      fileName: null,
      entry: null,
      successMessage: null,
      errors: new Errors(),
      currency: this.$appConfig.currency,
      companySizes: [
        { value: '1-50' },
        { value: '51-100' },
        { value: '101-250' },
        { value: '251-500' },
        { value: '501-1000' },
        { value: '1001-2500' },
        { value: '>2500' },
      ],
    };
  },
  computed: {
    ...mapState({
      newProfile: state => state.companyProfile.newCompany,
      profile: state => state.profile.profile,
      isCompanyProfileModalOpen: state => state.companyProfile.isCompanyProfileModalOpen,
      timezones: state => state.timezone.allTimezones,
    }),
    ...mapGetters({
      isRequiredToVerify: 'profile/isRequiredToVerify',
      isProfileIncomplete: 'profile/isProfileIncomplete',
      isProfilePending: 'profile/isProfilePending',
      isProfileComplete: 'profile/isProfileComplete',
      isProfileNoService: 'profile/isProfileNoService',
    }),
  },
  mounted() {
    this.$store.dispatch('companyProfile/getCompanyProfileNew');
  },
  methods: {
    updateTimezone(payload) {
      this.newProfile.timezone = payload.timezone.name;
    },
    updateSize(payload) {
      this.newProfile.size = payload.size.value;
    },
    uploadFile(file) {
      this.fileName = file.name;
      this.newProfile.main_logo = file;
    },
    userInitials(user) {
      if (user && user.name !== '' && user.name !== undefined) {
        const name = user.name.split(' ');
        return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
      }
    },
    updateAccount() {
      this.errors.clear();

      this.$store
        .dispatch('companyProfile/updateCompanyProfile')
        .then((res) => {
          this.errors.clear();
          if (res) {
            this.close();
          }
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            this.errors.set(err.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$store.commit('companyProfile/isCompanyProfileModalOpen', false);
      this.$store.dispatch('companyProfile/getCompanyProfile');
      this.$store.dispatch('companyProfile/getCompanyProfileNew');
    },
  },
};
</script>

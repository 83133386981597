<template>
  <div v-if="rating" class="content">
    <div class="panel-review mb-2">
      <div class="header pb-2">
        <div class="header-image-group">
          <div class="avatar">
            <img v-if="rating.user.main_image" :src="rating.user.main_image" alt="Profile">
            <span v-if="rating.user.name && !rating.user.main_image">{{ rating.user.name | initials }}</span>
          </div>
          <div class="text">
            <p class="name">{{ rating.user.name }}</p>
            <p class="info">{{ rating.user.job_title }}</p>
          </div>
        </div>
        <div class="header-star-date-group">
          <RatingStars
              :value="rating.rating"
              :icon="false"
          />
          <div class="date">{{ rating.created_at | date('DD MMM YYYY', 'YYYY-MM-DD') }}</div>
        </div>
      </div>
      <div class="body">
        <div class="text">
          {{ rating.comment }}
        </div>
        <div v-if="!rating.reply" class="icon" @click.prevent="openReplyModal">
          <ReplyIcon />
        </div>
      </div>
    </div>
    <ProfileResponse
        v-if="rating.reply && Object.keys(rating.reply).length > 0"
        :value="rating"
    />
  </div>
</template>

<script>
import RatingStars from '@/components/functional/RatingStars';
import ReplyIcon from '@/components/icons/ReplayIcon';
import ProfileResponse from '@/components/profile-company/ProfileResponse';
import { formatDate } from '@/utils/date.filter';
import { userInitials } from '../../utils/helpers';

export default {
  name: 'company-profile-review',
  i18nOptions: { namespaces: 'company-profile-reviews' },
  filters: {
    date: formatDate,
    initials: userInitials,
  },
  components: {
    RatingStars,
    ReplyIcon,
    ProfileResponse,
  },
  props: ['rating'],
  methods: {
    openReplyModal() {
      this.$store.commit('ratings/isReplyModalOpen', { isOpen: true, uuid: this.rating.rating_company_uuid });
    },
  },
};
</script>

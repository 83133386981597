<template>
    <div class="file-upload">

      <div class="file-upload__left">
        <p class="file-upload__text">{{ text }} <i v-if="optional">{{$t(" - Optional")}}</i></p>
        <small v-if="textFileLimit" class="file-upload__sub-text">{{ textFileLimit }}</small>

        <label class="file-label-custom-CV" :for="id" v-if="canEdit">
            <span class="btn file-upload__edit"><span>{{ $t(placeholder ? placeholder : 'upload') }}</span></span>
            <input class="" accept="image/* , application/pdf, .doc,.docx" type="file" :id="id" ref="file" :disabled="disabled" @change="passFile"/>
        </label>

        <p v-if="!canEdit">
          <button type="button" class="btn file-upload__edit" @click="edit">
            <i class="material-icons md-24">edit</i> {{fileName | truncate(10, '...')}}
          </button>
        </p>

        <AppError :errors="errors" field="file"></AppError>
      </div>

      <!-- <a v-if="fileName && !editMode" class="file-upload__right file-upload__img-success">
          <img src="img/placeholder-image.png" alt="">
          <i class="material-icons md-36 file-upload__img-checkmark">check</i>
      </a> -->

      <p v-if="flagFile" class="invalid-text" >{{$t('not-valid-type')}}</p>

    </div>

</template>

<script>
import AppError from '../AppError';

export default {
  i18nOptions: { namespaces: 'neoworq-form-template' },
  components: {
    AppError,
  },
  props: ['text', 'textFileLimit', 'fileName', 'errors', 'disabled', 'placeholder', 'optional', 'flagFile'],
  data() {
    return {
      editMode: false,
      id: null,
      uploadPercentage: 0,
    };
  },
  computed: {
    canEdit() {
      return !this.fileName || this.editMode;
    },
  },
  filters: {
    // eslint-disable-next-line func-names
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  mounted() {
    this.id = Math.ceil(Math.random() * 1000000);
  },
  methods: {
    passFile() {
      // console.log(this.$refs.file.files[0].size);
      if (this.$refs.file.files[0].size < 5000000) {
        this.errors.clear();
        this.$emit('changed', this.$refs.file.files[0]);
        this.editMode = false;
      } else {
        this.errors.set({ file: ['The file size must be maximum 5mb!'] });
      }
    },
    edit() {
      this.editMode = true;
      this.$emit('changed', null);
    },
  },
};
</script>

<template>
  <Modal
    class="dashboard-modal rating-modal"
    :value="isOpen"
    size="xmd"
    :header="false"
    :footer="false"
  >
    <div class="col-md-12 pl-0 pb-2">
      <div slot="header">
        <button type="button" aria-label="Close" class="close modal__close" @click.prevent="close">
          <i class="material-icons" aria-hidden="true">close</i>
        </button>
        <h3 class="modal-title pl-0 text-primary-light">
          {{ $t("header") }}
        </h3>
      </div>
      <div slot="content" class="content">
        <p v-if="profile">
          {{ profile.role.name === 'neo-worker' ? $t("content-neoworqer") : $t("content-client") }}
        </p>
        <div class="inner-content" v-if="profile">
          <div v-if="profile.role.name === 'neo-worker' && assignment.client" class="d-flex">
            <div class="avatar" v-bind:style="{ 'background-color': assignment.client.anonymous_color }">
              <img
                v-if="assignment.client.company.company_logo"
                :src="assignment.client.company.company_logo"
                alt="logo"
              />
              <span v-if="assignment.client.company.name && !assignment.client.company.company_logo">{{
                assignment.client.company.name | initials
              }}</span>
            </div>
            <div class="text">
              <p class="name">{{ assignment.client.company.name }}</p>
              <p class="info">{{ assignment.client.company.industry }}</p>
              <p class="info">{{ assignment.client.company.address }}</p>
            </div>
          </div>
          <div v-else class="d-flex">
            <div v-if="assignment.neoworker" class="d-flex">
              <div class="avatar" v-bind:style="{ 'background-color': assignment.neoworker.anonymous_color }">
                <img v-if="assignment.neoworker.main_img" :src="assignment.neoworker.main_img" alt="logo">
                <SearchAvatarIcon v-else/>
              </div>
              <div class="text">
                <p class="name">{{ assignment.neoworker.name }}</p>
                <p class="info">{{ assignment.neoworker.job_title }}</p>
              </div>
            </div>
          </div>
          <div class="stars" v-if="profile.role.name === 'neo-worker' && assignment.client">
            <p>{{ assignment.client.company.rating || 0 }}</p>
            <div
              class="star-ratings-css"
              :style="calculatePercent((100 / 5) * assignment.client.company.rating || 0)"
              :title="assignment.client.company.rating || 0 + '/5'"
            ></div>
          </div>
          <div class="stars" v-if="(profile.role.name === 'neo-client' || profile.role.name === 'neo-client-hr') && assignment.neoworker">
            <p>{{ assignment.neoworker.rating || 0 }}</p>
            <div
              class="star-ratings-css"
              :style="calculatePercent((100 / 5) * assignment.neoworker.rating || 0)"
              :title="assignment.neoworker.rating || 0 + '/5'"
            ></div>
          </div>
        </div>
        <div class="leave-rating">
          <p class="name">{{ $t("rating") }}</p>
          <div class="stars">
            <input
              class="star-ratings-css"
              :style="calculatePercent(rating.rating)"
              type="range"
              v-model="rating.rating"
              style="-moz-appearance: button;"
            />
          </div>
        </div>
        <div v-if="profile.role.name === 'neo-worker'" class="mt-2">
          <AppTextarea label="Write a review" v-model="rating.comment" />
        </div>
        <div class="button-rating">
          <button
            type="submit"
            class="btn btn-lg btn-primary btn-modal"
            @click.prevent="sendRating"
            :disabled="loading"
            v-if="!errors.has('assignment_uuid')"
          >
            {{ $t("send") }}
          </button>
          <div v-if="errors && errors.has('assignment_uuid')" class="availability-week__errors">
            <span class="help-block">
              <p class="help-block__error text-danger">
                <DangerIcon />
                <small>{{ $t('assignment-already-rated') }}</small>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';
import Notifications from '../services/notifications.service';
import AppTextarea from './forms/AppTextarea';
import { userInitials } from '../utils/helpers';
import Errors from '../utils/errors';
import DangerIcon from './icons/DangerIcon';
import SearchAvatarIcon from './icons/SearchAvatarIcon';

export default {
  name: 'rating-modal',
  i18nOptions: { namespaces: 'rating-modal' },
  data() {
    return {
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState({
      isOpen: state => state.ratings.isRatingModalOpen,
      rating: state => state.ratings.rating,
      profile: state => state.profile.profile,
      assignment: state => state.assignment.assignment,
    }),
  },
  components: {
    Modal,
    AppTextarea,
    DangerIcon,
    SearchAvatarIcon,
  },
  filters: {
    initials: userInitials,
  },
  methods: {
    close() {
      this.$store.commit('ratings/resetRating');
      this.$store.commit('ratings/isRatingModalOpen', false);
    },
    calculatePercent(rating) {
      return `--percent: ${Math.ceil(rating / 20) * 20 < 20 ? 20 : Math.ceil(rating / 20) * 20}%`;
    },
    sendRating() {
      this.loading = true;
      this.$store
        .dispatch('ratings/createRating', this.profile.role.name)
        .then(() => {
          Notifications.notify('success', 'Rating sent');
          this.close();
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            this.errors.set(err.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

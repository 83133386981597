<template>
  <div v-if="ratings" class="panel panel--reviews">
    <div class="panel-title pb-1">
      <h3>{{ $t("reviews") }}</h3>
    </div>
    <div v-if="ratings && ratings[0]">
      <div class="pl-2" v-for="rating in ratings" :key="rating.user.name">
        <ProfileUserReview
          :rating="rating"
        />
      </div>
    </div>
    <div class="pl-2" v-else>
      <p class="text-gray">{{ $t('no-ratings-yet') }}</p>
    </div>
  </div>
</template>

<script>
import ProfileUserReview from '@/components/profile-company/ProfileUserReview';

export default {
  name: 'company-profile-reviews',
  i18nOptions: { namespaces: 'company-profile-reviews' },
  props: ['ratings'],
  components: {
    ProfileUserReview,
  },
  methods: {},
};
</script>

<template>
  <div class="stars">
    <p v-if="icon === true">
      <RatingIcon class="rating-icon" />
    </p>
    <div class="stars-group">
      <p>{{ value }}</p>
      <div class="star-ratings-css" :style="calculatePercent()" :title="value+'/5'"></div>
    </div>
  </div>
</template>

<script>
import RatingIcon from '@/components/icons/RatingIcon';

export default {
  name: 'rating-stars',
  i18nOptions: { namespaces: 'rating-stars' },
  components: {
    RatingIcon,
  },
  methods: {
    calculatePercent() {
      const p = 100 / 5 * this.value;
      return `--percent: ${p}%`;
    },
  },
  props: ['value', 'hit', 'icon'],
};
</script>

<template>
  <transition name="fade" mode="out-in">
    <div class="dashboard-page">
      <div class="dashboard-page__row">
        <div class="dashboard-page__row--left">
          <ProfilePanel :profile="companyProfile" :bgColor="profile.anonymous_color" :editable="isEditable"/>
          <RatingsModal />
          <TheCompanyProfileModal />
        </div>
        <div class="dashboard-page__row--right">
          <ProfileAbout :profile="companyProfile" :coverImage="profile.cover_image ? profile.cover_image.src_lg : null" :editable="isEditable"/>
          <ProfileReviews :ratings="ratings"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import ProfilePanel from './profile-company/ProfilePanel';
import ProfileAbout from '@/components/profile-company/ProfileAbout';
import ProfileReviews from '@/components/profile-company/ProfileReviews';
import TheCompanyProfileModal from '@/components/profile-company/TheCompanyProfileModal';
import RatingsModal from './RatingsModal';

export default {
  components: {
    ProfilePanel,
    ProfileAbout,
    ProfileReviews,
    TheCompanyProfileModal,
    RatingsModal,
  },
  computed: {
    ...mapState({
      profile: state => state.profile.profile,
      companyProfile: state => state.companyProfile.company,
      ratings: state => state.ratings.ratings,
    }),
    isEditable() {
      return this.profile.role.name === 'neo-client';
    },
  },
  mounted() {
    this.$store.dispatch('companyProfile/getCompanyProfile');
    this.$store.dispatch('timezone/getTimezones');
  },
};
</script>

<template>
  <div class="panel panel--about">
    <div class="panel-about-heading pb-3">
      <img v-if="coverImage" :src="coverImage" alt="Cover image">
      <img v-else src="/img/cover.jpg" alt="Cover image">
      <div class="panel-heading pb-1">
        <a v-if="editable" @click.prevent="openCoverImageModal" href="#"><CoverEditIcon class="btn-edit" /></a>
        <h4 class="text-white">{{profile.name}}</h4>
        <h5 class="text-white">{{ profile.address }}</h5>
      </div>
    </div>
    <div class="panel-title pb-1">
      <h3>{{ $t("about") }}</h3>
      <a v-if="editable" @click.prevent="openAboutModal" href="#"><EditIcon2 class="btn-edit" /></a>
    </div>
    <div class="panel-body">
      <div v-if="profile" v-html="profile.about"></div>
      <p v-else class="text-primary-light">{{ $t("no-bio-yet") }}</p>
    </div>
    <TheCompanyAboutModal v-if="editable"/>
  </div>
</template>

<script>
import CoverEditIcon from '../icons/CoverEditIcon';
import EditIcon2 from '../icons/EditIcon2';
import TheCompanyAboutModal from '@/components/profile-company/TheCompanyAboutModal';

export default {
  name: 'company-profile-about',
  i18nOptions: { namespaces: 'company-profile-about' },
  components: {
    CoverEditIcon,
    EditIcon2,
    TheCompanyAboutModal,
  },
  props: ['profile', 'coverImage', 'editable'],
  methods: {
    openAboutModal() {
      this.$store.commit('companyProfile/isCompanyAboutModalOpen', true);
    },
    openCoverImageModal() {
      this.$store.dispatch('coverImages/getAllCoverImages').then(() => {
        this.$store.commit('coverImages/isCoverImagesModalOpen', true);
      });
    },
  },
};

</script>

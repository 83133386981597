var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{staticClass:"dashboard-modal",attrs:{"size":"lg","value":_vm.isCompanyAboutModalOpen,"header":false,"footer":false},on:{"input":_vm.updateModal}},[_c('div',{staticClass:"col-md-12 pl-0 pb-2"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('button',{staticClass:"close modal__close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.close}},[_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("close")])]),_c('h3',{staticClass:"modal-title pl-0 text-primary-light"},[_vm._v(_vm._s(_vm.$t('title')))])])]),_c('div',{staticClass:"dashboard-modal__content"},[_c('tinymce-editor',{attrs:{"id":"myEditor","api-key":"ffceq63r4f8ux7o18z1uh2hab5h9o2nney70hmaq18h0bmuc","model-events":"change keydown blur focus paste","init":{
       height: 400,
       menubar: false,
       inline: false,
       block_formats: 'Heading=h4;Paragraph=p',
       plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount emoticons'
       ],
       paste_as_text: true,
       setup:_vm.handlerFunction,
       toolbar:
         'undo redo | formatselect | bold italic | \
         bullist numlist | removeformat'
     }},on:{"selectionChange":_vm.handlerFunction},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}}),(_vm.errors)?_c('AppError',{attrs:{"errors":_vm.errors}}):_vm._e(),_c('div',{staticClass:"row mt-3 pb-1"},[_c('div',{staticClass:"col-xs-6"},[_c('button',{staticClass:"btn btn-block btn-primary-light",attrs:{"disabled":!_vm.editorContent || _vm.editorContent === _vm.content.about},on:{"click":_vm.updateModal}},[_vm._v(_vm._s(_vm.$t('change')))])]),_c('div',{staticClass:"col-xs-6"},[_c('button',{staticClass:"btn btn-block btn-gray",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('cancel')))])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
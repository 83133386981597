<template>
  <div class="panel panel--company-profile">
    <div class="panel-heading pb-1">
      <h3>{{ $t("profile") }}</h3>
      <a v-if="editable" @click.prevent="openCompanyProfileModal" href="#">
        <EditIcon2 class="btn-edit" />
      </a>
    </div>
    <div class="panel-body">
      <div class="profile__top">
        <div class="profile__top__avatar">
          <img v-if="profile && profile.company_logo" :src="profile.company_logo" alt="" />
          <span v-if="profile && !profile.company_logo" v-bind:style="{ 'background-color': bgColor }">{{ userInitials(profile) }}</span>
        </div>
        <div class="profile__top__info">
          <h4 class="text-primary">{{ profile.name }}</h4>
          <p>{{ profile.address }}</p>
          <small v-if="profile.timezone">{{ localTime }} {{ $t('local-time') }}</small>
        </div>
      </div>
      <div class="profile__body">
        <div v-if="profile.website" class="profile__body--content">
          <div class="title">{{ $t("website") }}</div>
          <div class="text">{{ profile.website }}</div>
        </div>
        <div v-if="profile.size" class="profile__body--content">
          <div class="title">{{ $t("size") }}</div>
          <div class="text">{{ profile.size }}</div>
        </div>
        <div v-if="profile.industry" class="profile__body--content">
          <div class="title">{{ $t("industry") }}</div>
          <div class="text">{{ profile.industry }}</div>
        </div>
        <div class="profile__body--content">
          <RatingStars
            :value="profile.rating || 0"
            :hit="5"
            :icon="true"
          />
        </div>
        <button v-if="editable" @click="goToPublicCompanyProfile" class="btn btn-block btn-primary-light-outline mt-4">
          {{ $t("see-public-profile") }}
        </button>
      </div>
    </div>
    <div class="pending" v-if="profile.condition === 'pending' && editable">
      <DangerIcon/>
      <p>{{$t("changes")}}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import EditIcon2 from '../icons/EditIcon2';
import RatingStars from '@/components/functional/RatingStars';
import DangerIcon from '../icons/DangerIcon';

export default {
  name: 'company-profile-panel',
  i18nOptions: { namespaces: 'company-profile-panel' },
  components: {
    EditIcon2,
    RatingStars,
    DangerIcon,
  },
  computed: {
    ...mapState({
      ratings: state => state.ratings.ratings,
    }),
  },
  data() {
    return {
      hide: false,
      localTime: null,
    };
  },
  props: ['profile', 'bgColor', 'editable'],
  mounted() {
    this.timezone();
  },
  methods: {
    timezone() {
      setInterval(() => {
        this.localTime = moment.tz(this.profile.timezone).format('h:mm a');
      }, 1000);
    },
    openCompanyProfileModal() {
      this.$store.commit('companyProfile/isCompanyProfileModalOpen', true);
    },
    userInitials(user) {
      if (user && user.name !== '' && user.name !== undefined) {
        const name = user.name.split(' ');
        return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
      }
    },
    goToPublicCompanyProfile() {
      this.$router.push(`/public-company-profile/${this.profile.uuid}`);
    },
  },
};
</script>

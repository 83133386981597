<template>
  <div class="panel-review panel-response mb-2">
    <div class="header">
      <div class="title mb-1">
        {{ $t('company-response') }}
      </div>
      <div class="text">
        {{ reply }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'company-profile-response',
  i18nOptions: { namespaces: 'company-profile-reviews' },
  components: {},
  props: ['value'],
  computed: {
    reply() {
      return this.value.reply.slice(3, this.value.reply.length - 4);
    },
  },
};
</script>
